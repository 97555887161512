export function useMaasVoucher() {
  const { apiInstance } = useShopwareContext();

  const addMaasVoucher = async (code: string) => {
    const maasVoucher = await apiInstance.invoke.post("/store-api/checkout/maas-voucher/add", { code });

    return maasVoucher.data;
  };

  const setFavoriteItem = async (id: string) => {
    const maasStore = await apiInstance.invoke.post("/store-api/checkout/favoriteitem/set/" + id);

    return maasStore.data;
  };

  return {
    addMaasVoucher,
    setFavoriteItem,
  };
}
